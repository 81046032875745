<template>
  <div style="padding: 15px;" v-show="this.allow">
    <div v-text="test"></div>

    <div>
      <el-select
          @change="getBudgets"
          style="width:100%"
          filterable
          placeholder="请选择项目"
          v-model="selected.contract_id">
        <el-option
            v-for="(item, key) in contracts"
            :key="key"
            :value="item.id"
            :label="item.name">
        </el-option>
      </el-select>
    </div>

    <div>
      <el-card :style="item.id === selected.budget_id ? 'border:1px solid red;margin-top:6px;' : 'margin-top:6px;'" v-for="(item, key) in budget" :key="key" @click="selectData(item)">
        <el-row>
          <el-col :span="24">
            <div><span v-text="item.name"></span></div>
            <div><span>可用预算</span>：<span v-text="item.result"></span></div>
          </el-col>
        </el-row>
      </el-card>
    </div>

  </div>
</template>

<script>
import {apiGetAgentJssdk, apiGetJssdk} from '@/api/commonApi'
import {apiGetProjectContracts, apiGetProjectBudget} from '@/api/shareWeworkProjectApi'
import * as ww from "@wecom/jssdk"

export default {
  created() {
    if (this.$route.query.token) {
      this.allow = true
      this.key = this.$route.query.key
      this.init()
      this.getContracts()
    }
  },
  data() {
    return {
      test: '',
      key: '',
      selected: {
        contract_id: '',
        budget_id: '',
      },
      allow: false,
      contracts: [],
      budget: [],
      jsapi: ['saveApprovalSelectedItems', 'getApprovalSelectedItems'],
    }
  },
  methods: {
    // 获取合同
    getContracts() {
      apiGetProjectContracts({
        per_page: 500,
        page: 1,
        company_id: this.$route.query.company_id,
      }).then(rsp => {
        this.contracts = rsp.data
      })
    },
    // 获取预算
    getBudgets(contract_id) {
      apiGetProjectBudget({
        per_page: 500,
        page: 1,
        project_contract_id: contract_id,
        company_id: this.$route.query.company_id,
      }).then(rsp => {
        this.budget = rsp.data
      })
    },
    init() {
      this.ua = navigator.userAgent.toLowerCase()
      if (/wxwork/i.test(this.ua)) {
        ww.register({
          corpId: 'ww07f39146d675193a',
          agentId: '1000003',
          jsApiList: this.jsapi,
          getConfigSignature: async (url) => {
            return await apiGetJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          getAgentConfigSignature: async (url) => {
            return await apiGetAgentJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          onConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
          onAgentConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
        })
      }
    },
    selectData (value) {
      let name = ''

      this.contracts.forEach((item) => {
        if (item.id === value.project_contract_id) {
          name = item.name
          return
        }
      })

      ww.saveApprovalSelectedItems({
        key: this.key,
        selectedData: [{
          key: value.id.toString(),
          value: name + '，预算项：' + value.name,
        }],
        success: () => {
          this.selected.budget_id = value.id
        },
        fail: (res) => {
          this.test = res
        }
      })
    }
  }

}
</script>
